import Dexie from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import moment from "moment";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { clearLocalData, common_post, HLog, isEmptyObject, validFragment } from "./helpers";
import { updateIndexedDb } from "./ducks/slices/dataSlice";
import { apiLayDsNgheNghiep } from "./pages/QuanLyHeThong/QuanLyDanhMuc/apisDanhMuc/apisDanhMuc";
import keys, { indexedDbKeys } from "./constants/keys";
import _ from "lodash";
import { apis } from "./constants";
import Constants from "./constants/Constants";
import { useKeycloak } from "@react-keycloak/web";
import { mainInstance } from "./libs/apiInstances";
import axios from "axios";
import QueryString from "query-string";
import { sendReloadAppointment } from "./ducks/slices/eventSlice";
import apiServices from "./config/apiServices";
import TINH from "./models/tinh.json";
import HUYEN from "./models/huyen.json";
import XA from "./models/xa.json";
import THX from "./models/thx.json";
import DAN_TOC from "./models/dantoc.json";
import QUOC_GIA from "./models/quocgia.json";
import i18n, { languageKeys } from "./i18n";
import { message, notification } from "antd";

export const ClickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const handleClick = (e) => {
    if (!!ref.current && !ref.current.contains(e.target)) onClickOutside();
    return;
  };
};

export const ScrollToTop = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  });

  return null;
});

// Hook custom sự kiện
export const useEventListener = (eventName, handler, element = window, condition = true, delay = 100) => {
  const savedHandler = useRef(); // ref chứa hàm xử lý

  useEffect(() => {
    savedHandler.current = handler; // lưu hàm xử lý vào ref
  }, [handler]);

  useEffect(() => {
    // kiểm tra có thể khởi tạo sự kiện lắng nghe event
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event) => savedHandler.current(event);
    if (!!condition) {
      // điều kiện sử dụng hàm lắng nghe sự kiện
      setTimeout(() => {
        element.addEventListener(eventName, eventListener);
      }, delay);
    } else {
      element.removeEventListener(eventName, eventListener);
    }
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, element, condition]);
};

export function useFakeSyncronousState(initValue) {
  const [lastState, updateState] = useState(initValue);

  let curr = lastState;

  const getState = () => curr;

  const setState = (newVal) => {
    curr = newVal;
    updateState(newVal);
    return curr;
  };

  return {
    getState,
    setState,
  };
}

export const useIndexedDb = () => {
  const table_info = {
    // [indexedDbKeys.tinh]: "MA_TINH_THANH,TEN_TINH_THANH",
    // [indexedDbKeys.huyen]: "++id,QUAN_HUYEN",
    //[indexedDbKeys.xa]: "++id,PHUONG_XA",
    //[indexedDbKeys.quoc_gia]: "ID,MA_QUOC_GIA,TEN_QUOC_GIA,TEN_GOI_KHAC,TEN_VIET_TAT",
    //[indexedDbKeys.dan_toc]: "ID,DAN_TOC",
    [indexedDbKeys.nghe_nghiep]: "STT,MA_NGHE_NGHIEP,TEN_NGHE_NGHIEP",
    //[indexedDbKeys.thx]: "++id, THX",
    [indexedDbKeys.kham_benh]: "++id, KHAM_BENH",
    [indexedDbKeys.cls]: "++id, CLS",
    [indexedDbKeys.template_pdf]: "++id, TEMPLATE_PDF",
    [indexedDbKeys.init_done]: "++id, INIT_DONE",
    [indexedDbKeys.phong]: "++id, PHONG",
    [indexedDbKeys.nhom_dv]: "++id, NHOM_DV",
  };

  const db = new Dexie(Constants.INDEX_DB_NAME);
  db.version(11).stores({
    ...table_info,
    fetch_date: `++id, date`,
  });
  db.open().catch(function (err) {
    console.error("Failed to open db: " + (err.stack || err));
    window.location.reload();
  });

  const count = useRef(0);
  const dispatch = useDispatch();

  const [loadingInitData, setLoadingInitData] = useState(true);

  const fetchDate = useLiveQuery(() => db.fetch_date.toArray(), []);
  const allJobs = useLiveQuery(() => db.nghe_nghiep.toArray(), []);
  const allKhamBenh = useLiveQuery(() => db.kham_benh.toArray(), []);
  const allCls = useLiveQuery(() => db.cls.toArray(), []);
  const allPhong = useLiveQuery(() => db.PHONG.toArray(), []);
  const allNhomDV = useLiveQuery(() =>  db.nhom_dv.toArray(), []);
  const allPdfTemplates = useLiveQuery(() => db.template_pdf.toArray(), []);
  const initDone = useLiveQuery(() => db.init_done.toArray(), []);
  const userProfile = useSelector((state) => state.auth.user);

  const isTodayUpdated = !!fetchDate && fetchDate.length > 0 && moment(fetchDate[0].value, "YYYYMMDD").isSame(moment(), "days");

  useEffect(() => {
    if (!!fetchDate && !!initDone && !!userProfile) {
      // const existPartnerCode = window.location.pathname.includes("/partner=");
      const isInitNotDone = !!initDone && initDone.length < 10;

      if (!isTodayUpdated || (isTodayUpdated && isInitNotDone)) {
        if (count.current > 0) return;
        ++count.current;
        checkUpdateDate(isTodayUpdated);
      } else {
        setLoadingInitData(false)
      }
    }
  }, [fetchDate, initDone, isTodayUpdated, userProfile]);

  const checkTableInitDone = useCallback(
    (table) => {
      try {

        if (!!initDone && initDone.length > 0) return initDone.some((item) => !!item[table]);
        return false;
      } catch (error) {
        HLog("[ERROR] check table init done", error);
      }
    },
    [initDone]
  );

  // * Kiểm tra xem danh sách đã được đẩy lên redux store chưa
  const useEffectDanhSach = (dbList, table) => {
    useEffect(() => {
      if (table === indexedDbKeys.nghe_nghiep) {
        HLog("this is Db DEXIE update ::::", dbList);
      }

      const isTableInitDone = checkTableInitDone(table);

      if (
        [
          indexedDbKeys.thx,
          indexedDbKeys.quoc_gia,
          indexedDbKeys.tinh,
          indexedDbKeys.huyen,
          indexedDbKeys.xa,
          indexedDbKeys.dan_toc,
          indexedDbKeys.nghe_nghiep
        ].includes(table)
      ) {
        dispatch(updateIndexedDb({ [table]: dbList }));
        return;
      }

      if (!!dbList && dbList.length > 0 && isTodayUpdated && isTableInitDone) {

        let checkTable = (key) => table === key;

        if (checkTable(indexedDbKeys.kham_benh)) {
          dispatch(updateIndexedDb({ [table]: dbList[0]?.KHAM_BENH }));
        } else if (checkTable(indexedDbKeys.cls)) {
          dispatch(updateIndexedDb({ [table]: dbList[0]?.CLS?.map((item, index) => ({ ...item, index: index.toString() })) }));
        } else if (checkTable(indexedDbKeys.template_pdf)) {
          dispatch(updateIndexedDb({ [table]: dbList[0]?.TEMPLATE_PDF }));
        } else if (checkTable(indexedDbKeys.phong)) {
          dispatch(updateIndexedDb({ [table]: dbList[0]?.PHONG?.filter(i => !i.KHOA) }));
        } 
        else if (checkTable(indexedDbKeys.nhom_dv)) {
          dispatch(updateIndexedDb({ [table]: dbList[0]?.NHOM_DV?.filter(i => !i.KHOA) }));
        } else {
          //dispatch(updateIndexedDb({ [table]: dbList }));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbList, isTodayUpdated, checkTableInitDone]);
  };

  useEffectDanhSach(TINH, indexedDbKeys.tinh);
  useEffectDanhSach(HUYEN, indexedDbKeys.huyen);
  useEffectDanhSach(XA, indexedDbKeys.xa);
  useEffectDanhSach(QUOC_GIA, indexedDbKeys.quoc_gia);
  useEffectDanhSach(DAN_TOC, indexedDbKeys.dan_toc);
  useEffectDanhSach(allJobs, indexedDbKeys.nghe_nghiep);
  useEffectDanhSach(THX, indexedDbKeys.thx);
  useEffectDanhSach(allKhamBenh, indexedDbKeys.kham_benh);
  useEffectDanhSach(allCls, indexedDbKeys.cls);
  useEffectDanhSach(allPhong, indexedDbKeys.phong);
  useEffectDanhSach(allPdfTemplates, indexedDbKeys.template_pdf);
  useEffectDanhSach(allNhomDV, indexedDbKeys.nhom_dv);

  const checkUpdateDate = async (skipInitUpdateFetchDate = false) => {
    try {
      if (!skipInitUpdateFetchDate) await initUpdateFetchDate();
      await initData(!skipInitUpdateFetchDate);
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingInitData(false);
    }
  };

  const initUpdateFetchDate = async () => {
    try {
      HLog("dexie - start initializing NGAY update");

      await db.init_done.clear();

      await db.fetch_date.clear();

      await db.fetch_date.add({ value: moment().format("YYYYMMDD") });
      // HLog("dexie - finish initializing NGAY update");
    } catch (error) {
      HLog("dexie - error while initializing NGAY update", error?.message);
    }
  };

  // * Chạy một loạt các hàm gọi API lấy danh sách
  const initData = async (forceInitData = false) => {
    setLoadingInitData(true);

    await initDs(indexedDbKeys.nghe_nghiep, apiLayDsNgheNghiep, forceInitData);
    await initDs(indexedDbKeys.kham_benh, apiLayDsDvKhamBenh, forceInitData);
    await initDs(indexedDbKeys.phong, apiLayDsPhong, forceInitData);
    await initDs(indexedDbKeys.cls, apiLayDsDvCls, forceInitData);
    await initDs(indexedDbKeys.template_pdf, apiLayTemplatePdf, forceInitData);
    await initDs(indexedDbKeys.nhom_dv, apiLayDsNhomDV, forceInitData)
    //await initDs(indexedDbKeys.dan_toc, apiLayDsDanToc, forceInitData);
    /// await initDs(indexedDbKeys.tinh, apiLayDsTinh, forceInitData);
    //await initDs(indexedDbKeys.quoc_gia, apiLayDsQuocGiaCoHieuLuc, forceInitData);
    //await initDs(indexedDbKeys.huyen, apiLayDsHuyen, forceInitData);
    //await initDs(indexedDbKeys.xa, apiLayDsXaPhuong, forceInitData);
    setLoadingInitData(false);
  };

  // * Gọi API lấy danh sách
  const initDs = async (table, api, forceInitData = false) => {
    try {
      HLog(`[${moment().format("HH:mm:ss")}] dexie - start initializing ds ${table}`);

      const isTableInitDone = checkTableInitDone(table);

      if (!forceInitData && isTableInitDone) return;

      // * clear toàn bộ data trong bảng
      await db[table].clear();

      // * convert danh sách các cột trong bảng theo tiêu chuẩn để lọc trường trong object
      const columnStandard = table_info[table].split(",").reduce((a, v) => ({ ...a, [v]: "" }), {});

      const res = await api({ limit: 1000000 });

      if (!!res) {
        const result = res.result;

        HLog(`[${moment().format("HH:mm:ss")}] dexie - response get ds ${table}`, result);

        // ! TỈNH
        if (table === indexedDbKeys.tinh) {
          // * gán mã tỉnh thành = mã hành chính để thuận tiện cho việc sử dụng
          let data = result.map((item) => ({
            ...item,
            MA_TINH_THANH: item.MA_HANH_CHINH,
          }));

          // * lọc các trường cần thiết trong object
          data = await validFragment(data, columnStandard);

          // * thêm tỉnh vào db
          await Promise.all(data.map((item) => addData(item, table)));
        }
        // ! HUYỆN
        else if (table === indexedDbKeys.huyen) {
          // * gán mã quận huyện = mã hành chính để thuận tiện cho việc sử dụng
          let data = result.map((item) => ({
            ...item,
            MA_QUAN_HUYEN: item.MA_HANH_CHINH,
          }));

          // * đánh index các huyện theo mã tỉnh thành
          data = await _.groupBy(data, "MA_TINH_THANH"); // return object

          // * thêm huyện vào db
          await addData({ QUAN_HUYEN: data }, table);
        }
        // * XÃ và Tỉnh-Huyện-Xã
        else if (table === indexedDbKeys.xa) {
          /**
           * ! XÃ
           */

          // * gán mã phường xã = mã hành chính để thuận tiện cho việc sử dụng
          let data = result.map((item) => ({
            ...item,
            MA_PHUONG_XA: item.MA_HANH_CHINH,
          }));

          // * đánh index các xã theo mã quận huyện
          data = await _.groupBy(result, "MA_QUAN_HUYEN"); // return object

          // * thêm xã vào db
          await addData({ PHUONG_XA: data }, table);

          /**
           * ! THX
           */

          let data_thx = {};

          // * đánh index các thx theo chữ cái đầu tiên của tên viết tắt
          result.forEach((item) => {
            // * lấy chữ cái đầu tiên của tên viết tắt
            const first_letter = item.TEN_VIET_TAT.slice(0, 1);

            // * thêm lần đầu nếu chưa có key chữ cái đầu tiên này
            if (!data_thx[first_letter]) data_thx[first_letter] = [item];
            // * đã tồn tại key chữ cái đầu tiên này
            else data_thx[first_letter] = [...data_thx[first_letter], item];
          });

          await db[indexedDbKeys.thx].clear();

          // * thêm thx vào db
          await addData({ THX: data_thx }, indexedDbKeys.thx);
        }
        // ! Khám bệnh
        else if (table === indexedDbKeys.kham_benh) {
          // * thêm dv khám bệnh vào db
          await addData({ KHAM_BENH: result }, table);
        }
        else if (table === indexedDbKeys.nhom_dv) {
          // * thêm dv khám bệnh vào db
          await addData({ NHOM_DV: result }, table);
        }
        // ! CLS
        else if (table === indexedDbKeys.cls) {
          // * thêm dv khám bệnh vào db
          await addData({ CLS: result }, table);
        }
        else if (table === indexedDbKeys.phong) {
          // * thêm phòng vào db
          await addData({ PHONG: result }, table);
        }
        // ! Template PDF
        else if (table === indexedDbKeys.template_pdf) {
          // * thêm dv khám bệnh vào db
          await addData({ TEMPLATE_PDF: result }, table);
        }
        // ! Các trường hợp còn lại
        else {
          // * lấy những trường cần thiết trong object
          const data = await validFragment(result, columnStandard);

          // * thêm data vào db
          await Promise.all(data.map((item) => addData(item, table)));
        }

        HLog(`dexie - init DS ${table}`);

        db.transaction("rw", indexedDbKeys.init_done, async () => {
          await addData(
            {
              [table]: true,
            },
            indexedDbKeys.init_done
          );

          if (table === indexedDbKeys.xa)
            await addData(
              {
                [indexedDbKeys.thx]: true,
              },
              indexedDbKeys.init_done
            );
        }).catch(function (err) {
          throw Error({ message: JSON.stringify(err) });
        });

        HLog(`[${moment().format("HH:mm:ss")}] dexie - finish initializing ds ${table}`);
      } else {
        HLog(`[${moment().format("HH:mm:ss")}] dexie - fail initializing ds ${table}`, res);
      }
    } catch (error) {
      HLog(`[${moment().format("HH:mm:ss")}] dexie - error initializing ds ${table}`, error?.message);
    }
  };

  const addData = async (values, key = "") => {
    try {
      await db[key].add(values);
    } catch (error) {
      HLog("dexie - [error]", error?.message);
    }
  };

  const apiLayTemplatePdf = async () => {
    try {
      const req = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
      };
      return await common_post(apis.lay_ds_template_pdf_theo_vien, req);
    } catch (error) {
      HLog("api lay ds template pdf", userProfile, error);
    }
  };

  const apiLayDsPhong = async ({ limit }) => {
    try {
      let request = {
        "partner_code": userProfile.partner_code,
        "BENH_VIEN_ID": userProfile.BENH_VIEN_ID,
        "IS_HIEU_LUC": 1,
        "page": 1,
        limit

      }
      return await common_post(apis.dm_phong_lay_ds, request);

    } catch (error) {
      HLog("api lay ds phòng pdf", userProfile, error);
    }
  }

  return { loadingInitData, db };
};

export const apiLayDsDvKhamBenh = async () => {
  try {
    let dataRequest = {
      page: 1,
      LOAI_DV_ID: "",
      // BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      // partner_code: userProfile.partner_code,
      PHAN_LOAI: "KHAM_BENH",
      limit: 1000000,
    };

    let rs = await common_post(apis.layDvKbCoPhong, dataRequest);

    if (rs.status === 'OK') {
      try {
        rs.result = _.orderBy(rs.result, ['THU_TU'], ['asc'])
          .map(i => ({
            ...i,
            LIST_GIA_THEO_DICH_VU: _.orderBy(i.LIST_GIA_THEO_DICH_VU, ['DOI_TUONG_MAC_DINH'], ['desc'])
          }));
      } catch (error) {
        console.log(error);
      }
    }
    return rs;
  } catch (error) {
    HLog(error);
  }
};

export const apiLayDsDvCls = async () => {
  try {
    const req = {
      // BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      // partner_code: userProfile.partner_code,
      limit: 1000000,
    };

    const rs = await common_post(apis.layDvClsCoPhong, req);

    if (rs.status === 'OK') {
      try {
        rs.result = rs.result.map(cls => {
          try {
            cls.DICH_VU = _.orderBy(cls.DICH_VU, ['THU_TU'], ['asc'])
              .map(i => ({
                ...i,
                LIST_GIA_THEO_DICH_VU: _.orderBy(i.LIST_GIA_THEO_DICH_VU, ['DOI_TUONG_MAC_DINH'], ['desc'])
              }))
          } catch (error) {
            throw error;
          }
          return cls;
        })
      } catch (error) {
        console.log(error);
      }
    }

    return rs;
  } catch (error) {
    HLog(error);
  }
};


export const apiLayDsNhomDV = async () => {
  try {
    const req = {
      // BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      // partner_code: userProfile.partner_code,
      limit: 1000000,
    };

    let res = await common_post(apis.dm_nhom_dvkt_lay_ds, req);
    return res
  } catch (error) {
    HLog(error);
  }
};

export const useAuth = () => {
  const { keycloak } = useKeycloak();

  const logout = () => {
    clearLocalData();
    HLog("loggingout keycloak:::");
    keycloak.logout();
  };

  return {
    logout,
  };
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const useConfigAxios = (ref) => {
  const { keycloak } = useKeycloak();
  const userProfile = useSelector((state) => state.auth.user);

  mainInstance.interceptors.request.use(
    async (config) => {
      let accessToken = localStorage.getItem(keys.access_token) || keycloak.token;
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        if (!(config.data instanceof FormData)) {
          config.headers['Accept'] = "application/json";
        } else {
          delete config.headers['Content-Type'];
        }
      }

      if (!!userProfile) {
        if (!(config.data instanceof FormData)) {
          config.data = {
            // NHANSU_ID: userProfile.NHANSU_ID,
            partner_code: userProfile.partner_code,
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            ...config.data,
          };
        } else {
          config.data.append('partner_code', userProfile.partner_code);
          config.data.append('BENH_VIEN_ID', userProfile.BENH_VIEN_ID);
        }
      }
      return config;
    },
    (error) => {
      ref.current?.open();

      return Promise.reject(error);
    }
  );

  mainInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let originalRequest = error.config;
      if (error.code === "ERR_NETWORK") {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          refreshAccessToken()
            .then((new_acccess) => {
              axios.defaults.headers.common["Authorization"] = `Bearer ${new_acccess}`;
              originalRequest.headers["Authorization"] = `Bearer ${new_acccess}`;
              processQueue(null, new_acccess);
              resolve(axios(originalRequest));
            })
            .catch((error) => {
              ref.current?.open();
              HLog("Error refresh token on hook", error);
              reject(error);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
    }
  );

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem(keys.refresh_token);

      const data = QueryString.stringify({
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      });

      const config = {
        method: "post",
        url: apiServices.KEYCLOAK_URL + "realms/" + apiServices.KEYCLOAK_REALM + "/protocol/openid-connect/token",
        auth: {
          username: apiServices.KEYCLOAK_CLIENT_ID,
          password: "oAkPKrCJetROQpSrS6IPYnMjzu7UvlVS",
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      let { data: resData } = await axios(config);

      if (resData) {
        localStorage.setItem(keys.access_token, resData.access_token);
        localStorage.setItem(keys.refresh_token, resData.refresh_token);

        return resData.access_token;
      } else {
        throw Error({ status: "KO", message: "Lỗi nè hihihi" });
      }
    } catch (error) {
      throw Error(error);
    }
  };

  return {};
};

export const useEventAppointment = () => {
  const userProfile = useSelector((state) => state.auth.user);
  const eventClient = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!userProfile && !isEmptyObject(userProfile)) {
      // initEventSource();
    }
  }, [userProfile]);

  const reloadAppointment = (event) => {
    HLog("RootRoute reloadAppointment event: ", event);
    if (event && !isEmptyObject(event)) {
      dispatch(sendReloadAppointment({ event }));
    }
  };

  const initEventSource = () => {
    HLog("RootRoute initEventSource userProfile: ", userProfile, eventClient.current);

    let hospital_id = userProfile.BENH_VIEN_ID;

    let url = apis.event_reload_cuoc_hen + "?BENH_VIEN_ID=" + hospital_id + "&&partner_code=" + userProfile.partner_code;

    eventClient.current?.connectServer(url);
  };

  return {
    eventClient,
    reloadAppointment,
  };
};

// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}


export const useApiQueue = () => {
  const [apiQueue, setApiQueue] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const executeApiCall = async (apiCall, onSuccess) => {
    try {
      setIsFetching(true);
      const result = await apiCall();
      onSuccess(result); // Gọi callback khi thành công và truyền giá trị trả về
    } catch (error) {
      console.error('Lỗi khi thực hiện cuộc gọi API:', error);
    } finally {
      setIsFetching(false);
      setApiQueue((prevQueue) => prevQueue.slice(1));
    }
  };

  const addToQueue = (apiCall, onSuccess) => {
    setApiQueue((prevQueue) => [...prevQueue, { apiCall, onSuccess }]);
  };

  useEffect(() => {
    if (!isFetching && apiQueue.length > 0) {
      const { apiCall, onSuccess } = apiQueue[0];
      executeApiCall(apiCall, onSuccess);
    }
  }, [apiQueue, isFetching]);

  return {
    addToQueue,
    isFetching,
  };
};

export const useChangeLanguage = () => {
  const userProfile = useSelector((state) => state.auth.user);

  /**
 * 
 * @param {Language} lang 
 */
  const handleChangeLangBackend = async (lang) => {
    try {
      let body = {
        NHANSU_ID: userProfile.NHANSU_ID,
        partner_code: userProfile.partner_code,
        language: lang,
      }
      const rs = await common_post(apis.switchLanguage, body);
      if (!(rs && rs.status === "OK")) {
        throw new Error(rs?.message)
      } else {
        return rs
      }
    } catch (error) {
      notification.error({
        message: i18n.t(languageKeys.cau_hinh_ngon_ngu_that_bai),
        placement: "bottomLeft"
      })
    }
  }

  /**
   * 
   * @param {Language} lang 
   */
  const handleChangeLanguage = async (lang) => {
    try {
      let rs = await handleChangeLangBackend(lang);
      if (rs) {
        i18n.changeLanguage(lang);
        await window.indexedDB.deleteDatabase(Constants.INDEX_DB_NAME);
        window.location.reload(true);
      }
    } catch (error) {
      HLog(error)
      console.log(error)
    }
  }

  return {
    onChangeLanguage: handleChangeLanguage
  }
}

/**
 * @typedef {'vi' | 'en'} Language
 */